import React, { useRef } from 'react';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';
import MainContent from './components/MainContent';
import './App.css';


function App() {
  const homeRef = useRef();
  const aboutRef = useRef();
  const servicesRef = useRef();
  const contactRef = useRef();

  return (
    <div className="App">
      <Header homeRef={homeRef} aboutRef={aboutRef} servicesRef={servicesRef} contactRef={contactRef}/>
      <HeroSection ref={homeRef} />
      <MainContent ref={servicesRef} />
      <ContactForm ref={contactRef} />
      <Footer ref={aboutRef} />
    </div>
  );
}

export default App;
