import React, { useState } from 'react';
import '../css/Header.css'; // Update CSS file as well

function Header({ homeRef, aboutRef, servicesRef, contactRef }) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    const scrollToRef = (ref) => {
        ref.current.scrollIntoView({behavior: 'smooth'});
        setIsMenuOpen(false); // Close the menu after clicking
    }

    return (
        <header className="header">
          <div className='mobileCorrection'>
            <div className="logo">
                Gleason Development
            </div>
            <div className="hamburger" onClick={toggleMenu}>
                {/* Hamburger Icon */}
                <div></div>
                <div></div>
                <div></div>
            </div>
          </div>
            <nav className={`navigation ${isMenuOpen ? 'open' : ''}`}>
                <ul>
                    <li><a href="#home" onClick={() => scrollToRef(homeRef)}>Home</a></li>
                    <li><a href="#about" onClick={() => scrollToRef(aboutRef)}>About Us</a></li>
                    <li><a href="#services" onClick={() => scrollToRef(servicesRef)}>Services</a></li>
                    <li><a href="#contact" onClick={() => scrollToRef(contactRef)}>Contact</a></li>
                </ul>
            </nav>
        </header>
    );
}

export default Header;
