import React, { forwardRef } from 'react';
import '../css/MainContent.css'; // Ensure to create this CSS file
import roomrosterrooms from '../roomrosterrooms.jpg';
import roomrostercalllist from '../roomrostercalllist.png';
import roomrosterworkorders from '../roomrosterworkorders.png';

const MainContent = forwardRef((props, ref) => {
  return (
    <div className="mainContent" ref={ref}>
        <div>
            <h2>Custom Solutions for Your Property Management Needs</h2>
            <p>With over 10 years of experience in the property management industry, we have the knowledge and expertise to help you with your property management needs.</p>
        </div>
        <div className='sectionOne'>
            <img src={roomrostercalllist} alt="Call list" className='roomRosterPic' />
            <p>track and manage leads from first touch to check in with our integrated Lead Management System</p>
        </div>
        <div className='sectionTwo'>
            <p>manage your properties with our integrated Property Management System</p>
            <img src={roomrosterrooms} alt="Room Roster" className='roomRosterPic' />
        </div>
        <div className='sectionOne'>
            <img src={roomrosterworkorders} alt="Work Orders" className='roomRosterPic' />
            <div>
                <p>track and manage work orders with our integrated Work Order Management System</p>
            </div>
        </div>
    </div>
  );
});

export default MainContent;
