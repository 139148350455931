// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA1PycHSOjOAVR73ndwYHXrd9QN4OkrB2Q",
  authDomain: "gleason-development-website.firebaseapp.com",
  projectId: "gleason-development-website",
  storageBucket: "gleason-development-website.appspot.com",
  messagingSenderId: "1195886327",
  appId: "1:1195886327:web:b4ab28a2c0c59febc475f2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
