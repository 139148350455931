import React, { forwardRef, useState } from 'react';
import '../css/ContactForm.css';
import { db } from '../firebaseConfig';
import { addDoc, collection } from 'firebase/firestore';

const ContactForm = forwardRef((props, ref) => {
  
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Process form data here
    console.log(formData);
    try {
      await addDoc(collection(db, 'siteInquiries'), formData )
    } catch (error) {
      console.log(error);
    }
    setFormSubmitted(true);
  };

  return (
    <div className="contact-form" ref={ref}>
      {
      formSubmitted ? (
        <div>
          <h2>Thank you for your submission!</h2>
          <p>We will get back to you as soon as possible</p>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <p>Please use the form below to contact us with any inquiries</p>
          <label>
            Name:
            <input type="text" name="name" value={formData.name} onChange={handleChange} />
          </label>
          <label>
            Email:
            <input type="email" name="email" value={formData.email} onChange={handleChange} />
          </label>
          <label>
            Message:
            <textarea name="message" value={formData.message} onChange={handleChange}></textarea>
          </label>
          <button type="submit">Submit</button>
        </form>
      )
      }
    </div>
  );
});

export default ContactForm;
