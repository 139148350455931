import React, { forwardRef } from 'react';
import '../css/HeroSection.css'; // Ensure to create this CSS file

const HeroSection = forwardRef((props, ref) => {
  return (
    <div className="hero-section" ref={ref}>
      <h1>Welcome to Gleason Development</h1>
      <p>Creating Custom Solutions for Property Management, Leads, and More</p>
      <br />
      <br />
      <div className='stats'>
        <div>
            {/* properties helped */}
            <h2>Properties Helped</h2>
            <h3>57+</h3>
        </div>
        <div>
            {/* leads managed */}
            <h2>Leads Managed</h2>
            <h3>700+</h3>
        </div>
      </div>
    </div>
  );
});

export default HeroSection;
