import React, { forwardRef } from 'react';
import '../css/Footer.css'; // Ensure to create this CSS file

const Footer = forwardRef((props, ref) => {
  return (
    <footer className="footer" ref={ref}>
      <div className="footer-content" itemscope itemtype="http://schema.org/Person">
        <p>&copy; {new Date().getFullYear()} <span itemprop="company">Gleason Development</span>
          . All rights reserved.</p>
        <p>Contact us: <a itemprop="email" href={`mailto:info@gleasondevelopment.com`}>info@gleasondevelopment.com</a></p>
        
        {/* Add any social media links here if necessary */}
      </div>
    </footer>
  );
});

export default Footer;
